body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 1.25rem;
  margin: 0px;
}
ul {
  margin-top: 0.25rem;
}
li {
  font-size: 0.75rem;
}
p {
  font-size: 1rem;
  margin-bottom: 0rem;
}
label {
  font-size: 0.75rem;
}
input,
label,
button,
.btn,
.MuiFormLabel-root,
.MuiInputBase-root,
.MuiInputBase-input,
.MuiOutlinedInput-notchedOutline,
.MuiCheckbox-root,
.MuiSelect-icon {
  color: white !important;
}
button,
.btn,
.MuiFormLabel-root,
.MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}
.Mui-focused .MuiFormLabel-root,
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(65, 131, 215, 1) !important;
  color: rgba()
}
.MuiFormControl-root {
  margin: 0.5rem !important;
}
.MuiFormLabel-root {
  text-align: left;
  margin-bottom: 0.25rem;
}
button,
.btn {
  /* margin: 1rem !important; */
  width: 4rem;
}
img {
  width: 100%;
  max-height: 400px;
}
