/* Form Control */
.form-control {
  width: 100%;
}

/* Number input arrow hide */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

/* Reset Button */
#resetButton {
  margin-top: 1rem;
  width: 100%;
}

/* Send Button */
#sendButton {
  margin-top: 1rem;
  padding: 1rem 0rem;
  width: 100%;
}

/* PSU Toggle */
#psu-toggle {
  height: 100%;
  display: grid;
  width: 50%;
  margin: auto;
}

/* Arb File Upload */
.MuiFormControl-root.uploadBtn,
#uploadArb {
  width: 97%;
}

/* Dropdown */
.MuiSelect-outlined.MuiSelect-outlined {
  padding: 1.25rem 1.5rem 1.25rem 1rem !important;
  margin: 0rem 0.5rem;
  width: auto;
}

#relay-group .MuiFormGroup-root {
  width: 100%;
  flex-direction: row;
}
span.MuiTypography-body1 {
  font-size: 0.75rem;
  width: 4rem;
  text-align: left;
}

/* Logs */
#logs {
  text-align: left;
  margin: 2rem;
}
#logs p {
  font-size: 0.75rem;
}
#logs #messages {
  padding-left: 1rem;
}
#logs #messages li {
  text-align: left;
  list-style: decimal;
  font-size: 0.55rem;
}
